import React from "react";
import { observer, inject } from "mobx-react";
import { withTranslation } from "react-i18next";
import SubForm1 from "./SubForm1";
import SubForm2 from "./SubForm2";
import SubForm3 from "./SubForm3";
import style from "./style.module.css";
import SubForm0 from "./SubForm0";
import { t } from "i18next";

@inject((store) => {
  const { myShopApiStore } = store;
  return { myShopApiStore };
})

@observer
class ItemUpdateForm extends React.Component {
  constructor(props) {
    super(props);
    const { isPackItemUpdating, cartItemUpdating, packItemUpdating } = this.props.myShopApiStore;

    const itemUpdating = isPackItemUpdating ? packItemUpdating : cartItemUpdating;
    this.item = itemUpdating.item;
    
    this.state = {
      hasError: false,
      productsAssociated: null,
      currentStep: this.item.shopProductOptions?.length > 0 ? 0 : (this.item.shopBaseToppings?.length > 0 ? 1 :  2),
      selectedBaseToppings: itemUpdating.options?.baseToppings ?? [],
      selectedProductOption: itemUpdating.options?.productOption ?? {},
      selectedToppings: itemUpdating.options?.toppings ?? [],
      optionsHistory: {
        productOption: this.saveOldProductOption(),
        toppings: this.saveOldToppings(),
        baseToppings: this.saveOldBaseToppings()
      },
    };
  }

  saveOldProductOption = () => {
    const { cartItemUpdating,
      packItemUpdating,
      isPackItemUpdating } = this.props.myShopApiStore;

    const { productOption } = isPackItemUpdating ? (packItemUpdating.options ?? {}) : (cartItemUpdating?.options ?? {});

    return productOption ? {...productOption} : {};
  }

  saveOldToppings = () => {
    const { cartItemUpdating,
            packItemUpdating,
            isPackItemUpdating } = this.props.myShopApiStore;

    const { toppings } = isPackItemUpdating ? (packItemUpdating.options ?? {}) : (cartItemUpdating?.item.options ?? {});
  
    return toppings ? toppings.map(t => ({...t, quarterList: [...t.quarterList]})) : [];
  }

  saveOldBaseToppings = () => {
    const { cartItemUpdating,
            packItemUpdating,
            isPackItemUpdating } = this.props.myShopApiStore;

    let newBaseToppings = [];
    if(isPackItemUpdating){
      packItemUpdating.options && packItemUpdating.options.baseToppings?.forEach(t => {
        newBaseToppings.push(t)
      })
    }
    else{
      cartItemUpdating?.options && cartItemUpdating.options.baseToppings?.forEach(t => {
        newBaseToppings.push(t)
      })
    }

    return newBaseToppings;
  }

  closeMaxToppingMsg = () => {
    this.setState({hasError: false})
  }
  
  handleSubmit = async (event) => {
    event.preventDefault();
    const { closeCartItemForm,
            editCartItemUpdating,
            calcTotalCartPrice,
            isPackItemUpdating,
            closePackItemForm,
            cartItemUpdating,
            packItemUpdating,
            setStepNum,
            inAssociatedStage,
            getProductsAssociated,
            checkAssociatedProductsInCart
          } = this.props.myShopApiStore;
    
    //toppings that added to the pizza tray and not just chosen in checkbox
    const isPizza =  (packItemUpdating && packItemUpdating.item.product.productType === 'pizza') || (cartItemUpdating?.item.product.productType === 'pizza');
    if(isPizza){
      const toppingsAddedToPizza = this.state.selectedToppings.filter(topping => topping.quarterList.some(q => q === true));
      editCartItemUpdating(this.state.selectedProductOption, toppingsAddedToPizza, this.state.selectedBaseToppings) 
    }
    calcTotalCartPrice();
    
    if(isPackItemUpdating){
      const isTheLastStep = cartItemUpdating.steps.length === this.props.currentStep;
      //setStepNum(1);
      if(isTheLastStep){
        closePackItemForm();
        /** IS IT REALLY NESSESSARY TO CHECK IT HERE???  */
        // await this.props.checkForProductsAssociated(this.props.myShopApiStore.cartItemUpdating?.item, true); //Added!
        // await getProductsAssociated();
        closeCartItemForm();
      }else{
        closePackItemForm();
        this.props.nextStep();
      }
    }else{
      // await this.props.checkForProductsAssociated(this.props.myShopApiStore.cartItemUpdating?.item, true); //Added!
      // await getProductsAssociated();
      closeCartItemForm();
    }

    if (inAssociatedStage === true) {
        //  create a variable to hold the associated products
        let shopProductsAssociatedIds = checkAssociatedProductsInCart();

        if (shopProductsAssociatedIds.length) {
            await getProductsAssociated();
        }else {
          
        }
    }
  };

  toggleToppingOnQuarter = (topping, quarterIdx) => {
    if(!topping || !topping.id) return;

    const { calcTotalCartPrice, editCartItemUpdating, isPackItemUpdating, packItemUpdating, cartItemUpdating} = this.props.myShopApiStore;

    const { maxToppings } = isPackItemUpdating ? packItemUpdating.item.product : cartItemUpdating?.item.product;
    const { toppingsFree } = this.props;
    
    let toppingsAddPaid = null;
    if(isPackItemUpdating){
      toppingsAddPaid = packItemUpdating.toppingsAddPaid;
    }

    const maxToppingsIsOff = maxToppings === null || maxToppings === undefined || maxToppings === ''; 

    const quartersAmount = this.getQuartersAmount() + (topping.quarterList[quarterIdx]?-1:0);

    //console.log(maxToppings, quartersAmount, maxToppingsIsOff, toppingsAddPaid, toppingsFree);

    if(((maxToppings*4) > quartersAmount || maxToppingsIsOff) &&
                  (toppingsAddPaid !== 0 || quartersAmount < (toppingsFree*4))){
          if(this.state.hasError){
            this.setState({hasError: false})
          }
    }else{
        if(!this.state.hasError){
          this.setState({hasError: true})
        }
        return;
    }

    /*
    const quartersAmount = this.getQuartersAmount()+(topping.quarterList[quarterIdx]?-1:1); 
    
    if((topping.generalLimit && quartersAmount > topping.generalLimit) || (topping.maxAmount && quartersAmount > topping.maxAmount))
    {
      !this.state.hasError && this.setState({hasError: true})
      return;
    }
    this.state.hasError && this.setState({hasError: false});
    */

    //switching (enable/disable) topping on quarter
    topping.quarterList[quarterIdx] = !topping.quarterList[quarterIdx];
    //updating 'selectedToppings' state with new topping (that with the new quarterList property)
    const newSelectedToppings = [];
    this.state.selectedToppings.forEach(t=>{
      newSelectedToppings.push(t.id===topping.id?topping:t)
    })
    this.setState({
      selectedToppings: newSelectedToppings
    })

    //update option changes
    editCartItemUpdating(this.state.selectedProductOption, newSelectedToppings, this.state.selectedBaseToppings)

    calcTotalCartPrice();
  }

  toggleToppingOnAllQuarters = (topping) => {
    const { calcTotalCartPrice, editCartItemUpdating, isPackItemUpdating, packItemUpdating, cartItemUpdating } = this.props.myShopApiStore;
    const { maxToppings } = isPackItemUpdating ? packItemUpdating.item.product : cartItemUpdating?.item.product;
    const { toppingsFree } = this.props;
    
    let toppingsAddPaid = null;
    if(isPackItemUpdating){
      toppingsAddPaid = packItemUpdating.toppingsAddPaid;
    }


    if(topping && topping.id){

      // checks if topping is on all pizza quarters
      const onWholeTray = topping.quarterList.every(q => q);

      // checks if maxTopping === '' (in interface management) -> its mean that no have limited toppings amount!
      const maxToppingsIsOff = maxToppings === null || maxToppings === undefined || maxToppings === ''; 



      //////////////////////////////


      //limit toppings
      const quartersAmount = this.getQuartersAmount(); 
      const emptysToppingQuarters = topping.quarterList.filter(q => !q).length;

      if(!onWholeTray){
        if(((maxToppings*4) > quartersAmount || maxToppingsIsOff) &&
                  (toppingsAddPaid !== 0 || quartersAmount < (toppingsFree*4))){
          if(this.state.hasError){
            this.setState({hasError: false})
          }
        }
        else{
            if(!this.state.hasError){
              this.setState({hasError: true})
            }
            return;
        }
      }
      
      // const quartersAmount = this.getQuartersAmount(); 
      // const emptysToppingQuarters = topping.quarterList.filter(q => !q).length;
      // if((((maxToppings*4) <= quartersAmount) || (toppingsAddPaid === 0 && (toppingsFree*4) <= quartersAmount)) && !onWholeTray && !maxToppingsIsOff){
      //   !this.state.hasError && this.setState({hasError: true})
      //   return;
      // }
      // else{
      //   this.state.hasError && this.setState({hasError: false})
      // }
      

      //////////////////////////////


      //make topping with(or not) limited
      const newTopping = {...topping, quarterList: []}
      if(onWholeTray){ //remove topping to all quarters
        newTopping.quarterList = Array(4).fill(false);
      }
      else{ //add topping to some/all of the quarters
        newTopping.quarterList = [...topping.quarterList]
        let leftQuartersAmount = (toppingsAddPaid === 0 && toppingsFree < maxToppings) ? (toppingsFree*4)-quartersAmount : (maxToppings*4)-quartersAmount; 
        leftQuartersAmount = leftQuartersAmount < 0 && toppingsAddPaid === 0 ? 4 + leftQuartersAmount : leftQuartersAmount
        if(leftQuartersAmount < emptysToppingQuarters && (!maxToppingsIsOff || toppingsAddPaid === 0)){  //add topping to SOME of the quarters
          let count = 0;
          topping.quarterList.forEach((quarter,q_idx) => {
            if(count < leftQuartersAmount){
              if(!quarter){
                newTopping.quarterList[q_idx] = true;
                count++;
              }
            }
          })
        }
        else{  //add topping to ALL of the quarters
          newTopping.quarterList = Array(4).fill(true);
        }
      }
      // const newTopping = {...topping, quarterList: onWholeTray ? Array(4).fill(false) : Array(4).fill(true)};
      let newSelectedToppings = [];
      this.state.selectedToppings.forEach(t => {
        if(t.id === newTopping.id){
          newSelectedToppings.push(newTopping);
        }
        else{
          const ql = [...t.quarterList] //new quarterList
          const newT = {...t, quarterList: ql}
          newSelectedToppings.push(newT);
        }
      })
      //updating 'selectedToppings' state with the new topping
      this.setState({
        selectedToppings: newSelectedToppings
      })
  
      //update option changes
      editCartItemUpdating(this.state.selectedProductOption, newSelectedToppings, this.state.selectedBaseToppings)
  
      //calculate total price
      calcTotalCartPrice();
    }
  }

  // calculate the sum of toppings in all the quarters
  getQuartersAmount = () => {
    let count = 0;
    this.state.selectedToppings.forEach(topping => {
      topping.shopToppingAmount && topping.quarterList.forEach(quarter => {
        count += quarter ? 1 : 0;
      })
    })
    return count;
  }

  handleChange = (event, srcCtrl=null) => {
    //console.log(event);
    const { name, value, type, className} = srcCtrl || event.target;// || event.srcElement;
    const { cartItemUpdating,
            editCartItemUpdating,
            calcTotalCartPrice,
            packItemUpdating,
            isPackItemUpdating } = this.props.myShopApiStore;
    const { maxToppings } = isPackItemUpdating ? packItemUpdating.item.product : cartItemUpdating?.item.product;
    const { shopProductOptions, shopToppings, shopBaseToppings } = isPackItemUpdating ? packItemUpdating.item : cartItemUpdating?.item;
    const { toppingsFree } = this.props;
    const toppingsAddPaid = isPackItemUpdating?packItemUpdating.toppingsAddPaid:null;
    const productType = (isPackItemUpdating)?packItemUpdating.item.product.productType:cartItemUpdating?.item.product.productType;
    const isInsidePack = cartItemUpdating?.item.product.productType==='pack';
    let { optionsPaid } = isPackItemUpdating && packItemUpdating;
    
    optionsPaid = !isPackItemUpdating ? true : optionsPaid;

    if(name === "selectedBaseToppings"){
      //value === id
      
      const isChecked = this.state.selectedBaseToppings.some(t => t.id == value)
      const newSelectedBaseToppings = isChecked
                                      ? 
                                        this.state.selectedBaseToppings.filter(t => t.id != value)
                                      :
                                        [...this.state.selectedBaseToppings, {id: value}]


      // //find unSelected base toopings
      // const newUnSelectedBaseToppings = shopBaseToppings?.filter(shopBaseTopping => 
      //   newSelectedBaseToppings?.every(nsBaseTopping => nsBaseTopping.id != shopBaseTopping.id))

      // //updating 'selectedBaseToppings' state with the new topping
      this.setState({
        selectedBaseToppings: newSelectedBaseToppings,
        // unSelectedBaseToppings: newUnSelectedBaseToppings
      })
      

              
      editCartItemUpdating(this.state.selectedProductOption,
                           this.state.selectedToppings,
                           newSelectedBaseToppings)

     
    }
    else if(name === "selectedToppings" ){ //update checked toppings
      const item = shopToppings.filter(topping => topping.id == value)[0];
      const parts = (productType === 'pizza') ? 4 : 1;
     
      const generalLimit = Math.min(...([ // All limits for toppings of the product
               maxToppings*parts, 
              //item.topping.maxAmount, 
              (isPackItemUpdating && toppingsAddPaid === 0)?toppingsFree*parts:0
            ].filter(i=>i)));

      const topping = this.state[name].find(item => item.id == value) 
        || {          
            name: item.topping.name,
            price: item.price,
            id: item.id,
            codeName: item.topping.codename,
            maxAmount: item.topping.maxAmount, // Limit for the topping
            generalLimit: generalLimit, //pass it inside for checking pizza parts
            quarterList: Array(4).fill(false),
            shopToppingAmount: 0,
            paidAmount: 0, // how many is free from shopToppingsAmount
            sum: 0, //when toppings amount less than toppingsFree
          };
      

      var toppingsAmount = (productType === 'pizza')?this.getQuartersAmount()
        :this.state[name].reduce((acc, top) => acc + top.shopToppingAmount, 0);
      
     
      const maxReached = (toppingsAddPaid === 0 && toppingsFree === 0) || (generalLimit>0 && toppingsAmount>=generalLimit) || (topping.maxAmount && topping.shopToppingAmount>=topping.maxAmount);
      // MAKE REQUESTED ADDITION/SUBTRACTION
      const delta = (!maxReached && ((type === 'checkbox' && topping.shopToppingAmount === 0) || className==="buttonPlus"))?1:
                                 (type === 'checkbox' ?-topping.shopToppingAmount:(className==="buttonMinus"?-1:0));
      toppingsAmount+=delta;
      topping.shopToppingAmount+=delta;
      (productType !== 'pizza') && toppingsAmount>(toppingsFree ?? 0) && (topping.paidAmount + delta) > -1 && (topping.paidAmount += delta);
      let toppings = [...this.state[name]];
      
      topping.sum = topping.price * (isInsidePack?topping.paidAmount:topping.shopToppingAmount);

      // REMOVE OR UPDATE THE TOPPING IN THE TOPPINGS LIST
      if (topping.shopToppingAmount < 1) toppings = toppings.filter(topping => topping.id != value);
      else if 
        ((!generalLimit || toppingsAmount <= generalLimit) 
        && (!topping.maxAmount || topping.shopToppingAmount <=topping.maxAmount))
          toppings=[...this.state[name].filter(topping => topping.id != value), topping];
  
      //RECALCULATE PAID TOPPINGS
      const toppingsAmountEnd = (productType === 'pizza')?this.getQuartersAmount():toppings.reduce((acc, item)=> acc + item.shopToppingAmount, 0);
      if (toppingsAmountEnd>=toppingsFree*parts)  
        while((toppingsAmountEnd-toppingsFree*parts) < (toppings.reduce((acc, item)=>acc + item.paidAmount, 0))) {
         //find any other topping and remove one paid addition
          let idx = toppings.findIndex(topping => topping.paidAmount>0);
          toppings[idx].paidAmount--;
          toppings[idx].sum = toppings[idx].price * toppings[idx].paidAmount;
        }

        // console.log(`maxToppings ${maxToppings} toppingsFree ${toppingsFree} toppingsAmount ${toppingsAmount} toppingsAddPaid ${toppingsAddPaid} 
        // toppingMaxAmount ${item.topping.maxAmount} topping.shopToppingAmount ${topping.shopToppingAmount} maxReached ${maxReached} parts ${parts} toppingsAddPaid === 0 ${(toppingsAddPaid === 0 && ((toppingsAmount / parts) === toppingsFree))}` );
      //clear checkbox toppings picked of pizza item that not on pizza quarters
      (productType === 'pizza') && (toppings = toppings.filter(t => t.quarterList.some(q => q) || t.id === topping.id));
      this.setState({
        hasError:  delta === 0//(generalLimit && toppingsAmountEnd>=generalLimit || topping.maxAmount && topping.shopToppingAmount>=topping.maxAmount && delta===1)
      })     
      this.setState({selectedToppings: toppings});
      editCartItemUpdating(this.state.selectedProductOption, toppings, this.state.selectedBaseToppings);
    }
    else if (name === "selectedProductOption"){ // update product option
      const productOption = shopProductOptions.filter(po => po.id == value)
      
      //if(!optionsPaid && productOption[0].price > 0) return;
      
      const option = {
        name: productOption[0].productOption.name,
        price: optionsPaid ? productOption[0].price : 0,
        id: productOption[0].id,
      }

      this.setState({
        [name]: option,
      });

      editCartItemUpdating(option, this.state.selectedToppings, this.state.selectedBaseToppings)
    }

    calcTotalCartPrice();

  };

  cancleUpdateCartItem = () => {
    const { editCartItemUpdating,
            calcTotalCartPrice,
            closeCartItemForm,
            isPackItemUpdating,
            closePackItemForm,
            setStepNum,
            resetStepNum,
            isOpenForEdit } = this.props.myShopApiStore;
      
      //update unsave options
      editCartItemUpdating(this.state.optionsHistory.productOption, this.state.optionsHistory.toppings, this.state.optionsHistory.baseToppings);
      calcTotalCartPrice();
    
    //close form
    if(isPackItemUpdating){
      resetStepNum();
      closePackItemForm();
    }
    else{
      closeCartItemForm();
    }

    if(!isOpenForEdit && (!isPackItemUpdating || this.props.isLonely)){ // if its the initial edit (and not packitem) -> remove from cart!
      const { removeCartItem, thisCart, closeCartItemForm } = this.props.myShopApiStore;
      const { cartItemList } = thisCart;
      this.props.isLonely && closeCartItemForm();
      const idx = cartItemList.length - 1;
      removeCartItem(idx);
    }
  }

  removeTopping = (topping) => {
    const { calcTotalCartPrice, editCartItemUpdating } = this.props.myShopApiStore;

    //updating 'selectedToppings' state with the new topping
    let newToppings = [...this.state.selectedToppings.filter(t => t.id != topping.id)];
    if(this.props.toppingsFree){ //update firsts free toppings price
      for(let i=0 ; i < newToppings.length ; i++){
        if(i < this.props.toppingsFree){
          newToppings[i].price = 0;
        }
      }
    }
    this.setState({
      selectedToppings: newToppings
    })

    //update option changes
    editCartItemUpdating(this.state.selectedProductOption,
            this.state.selectedToppings.filter(t => t.id != topping.id),
            this.state.selectedBaseToppings);

    //calculate total price
    calcTotalCartPrice();
  }

  _next = () => {
    const nextStep = this.state.currentStep === 0 && !this.item.shopBaseToppings?.length ? 2 : this.state.currentStep + 1;
    nextStep <= 2 && this.setState({
      currentStep: nextStep,
    });
  };

  _prev = () => {
    const prevStep = this.state.currentStep === 2 && !this.item.shopBaseToppings?.length ? 0 : this.state.currentStep - 1;
    prevStep < 0 
      ? this.cancleUpdateCartItem()
      : this.setState({
        currentStep: prevStep,
      });
  };

  /*
   * the functions for our button
   */
  previousButton() {
    return (
      <button
        type="button"
        onClick={() => this._prev()}
        className={style.prevButton}
      >
        {t('previous')}
      </button>
    );

  }

  nextButton() {
    return (
      <button 
        type="button" 
        onClick={() => this._next()}
        className={style.nextButton}
        >
        {t('next')}
      </button>
    );

  }

  render() {
    const { cartItemUpdating, packItemUpdating, isPackItemUpdating, shop, shopData } = this.props.myShopApiStore;

    const { t } = this.props;

    const { shopBaseToppings,
            shopProductOptions,
            shopToppings, 
            product } = this.item;

    const { optionsDescription,
            toppingsDescription,
            baseToppingsDescription,
            productType,
            maxToppings,
            oneToppingMode } = product;
    
    let { optionsPaid } = isPackItemUpdating && packItemUpdating;
    
    optionsPaid = !isPackItemUpdating ? true : optionsPaid;

    const hasBaseToppings = shopBaseToppings?.length > 0;
    const hasToppings = (shopToppings?.length > 0) && (!isPackItemUpdating || packItemUpdating.toppingsAddPaid == 1 || packItemUpdating.toppingsFree > 0);
    const hasProductOptions = shopProductOptions?.length > 0;

    const hasNext = (this.state.currentStep == 0 && (hasToppings || hasProductOptions)) || (this.state.currentStep == 1 && hasToppings);
    const hasPrev = (this.state.currentStep == 2 && (hasBaseToppings || hasProductOptions)) || (this.state.currentStep == 1 && hasProductOptions);

    const toppingsAddPaid = isPackItemUpdating ? packItemUpdating.toppingsAddPaid : null;

    const productName = isPackItemUpdating ? packItemUpdating.item.product.name : cartItemUpdating?.item.product.name;
    return (
      <div className={style.Container}>
        <div className={style.TopContent}>
          <div className={style.CloseButton}>
            <button onClick={this.cancleUpdateCartItem}>✕</button>
          </div>
          <div className={style.ItemName} title={productName}>
            {productName}
          </div>
          { (this.state.currentStep > Math.min( 
                shopProductOptions?.length ? 0 : Infinity, 
                shopBaseToppings?.length ? 1 : Infinity
              )) &&
              <div className={ style.CloseButton } style={{ cursor: 'pointer', fontSize: '2.5rem' }} onClick={this._prev}>&#60;</div> }
        </div>
        {/* <div className={style.BodyContent}> */}
          <form > {/*onSubmit={this.handleSubmit}*/}
            {//now it is the first step
              this.state.currentStep === 0 && <SubForm1  
              currencyName={shopData.country.currencyName}
              handleChange={this.handleChange}
              shopProductOptions={shopProductOptions}
              selectedProductOption={this.state.selectedProductOption}
              optionsDescription={optionsDescription}
              optionsPaid={optionsPaid}
              withoutPrices={shop.withoutPrices}
            />}
            {this.state.currentStep === 1 && <SubForm0
              handleChange={this.handleChange}
              shopBaseToppings={shopBaseToppings}
              selectedBaseToppings={this.state.selectedBaseToppings}
              baseToppingsDescription={baseToppingsDescription}
              withoutPrices={shop.withoutPrices}
            />}
            {this.state.currentStep === 2 &&
              (productType === "pizza" ? 
              <SubForm3
                currencyName={shopData.country.currencyName}
                handleChange={this.handleChange}
                shopToppings={shopToppings}
                selectedToppings={this.state.selectedToppings}
                selectedProductOption={this.state.selectedProductOption}
                toppingsDescription={toppingsDescription}
                toppingsFree={this.props.toppingsFree}
                setQuarterList={this.setQuarterList}
                toggleToppingOnQuarter={this.toggleToppingOnQuarter}
                toggleToppingOnAllQuarters={this.toggleToppingOnAllQuarters}
                removeTopping={this.removeTopping}
                optionsPaid={optionsPaid}
                hasError={this.state.hasError}
                hasProductOptions={hasProductOptions}
                maxToppings={maxToppings}
                toppingsAddPaid={toppingsAddPaid}
                closeMaxToppingMsg={this.closeMaxToppingMsg}
                withoutPrices={shop.withoutPrices}
              />
              :
              <SubForm2
                currencyName={shopData.country.currencyName}
                handleChange={this.handleChange}
                shopToppings={shopToppings}
                selectedToppings={this.state.selectedToppings}
                toppingsDescription={toppingsDescription}
                toppingsFree={this.props.toppingsFree}
                maxToppings={maxToppings}
                toppingsAddPaid={toppingsAddPaid}
                closeMaxToppingMsg={this.closeMaxToppingMsg}
                hasError={this.state.hasError}
                oneToppingMode={oneToppingMode}
                withoutPrices={shop.withoutPrices}
              />)
            }
            
            <div className={style.FormButtons}>
              { hasNext ? <div className={style.NextBtn}>{this.nextButton()}</div> : null }
              <div className={style.PrevAndSubmitBtns}>
                <div className={style.SubmitFormBtn}> 
                  {!hasNext ? 
                    <button className={style.nextButton} onClick={(e) => this.handleSubmit(e)} {...(
                        (productType === 'pizza' && this.state.selectedToppings?.length
                          && !this.state.selectedToppings[this.state.selectedToppings.length-1].quarterList.reduce((acc, item)=> acc || item, false )?({disabled:true}):{}))}
                    >{t('add to order')}</button>
                  : null}
                </div>
                { hasPrev ? <div className={style.PreviousBtn}>{this.previousButton()}</div> : null }
              </div>
            </div>
          </form>
        {/* </div> */}


      </div>
    );
  }
}

export default withTranslation()(ItemUpdateForm);
